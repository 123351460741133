import ThemeMode from "lib/theme/ThemeMode";
import {createContext} from "react";

export type MontaThemeContextValue = {
    themeMode: ThemeMode;
    toggleTheme: () => void;
    updateThemeMode: (themeMode: ThemeMode) => void;
}

export const MontaThemeContext = createContext<MontaThemeContextValue | undefined>(undefined);
