import {useSettingsContext} from "lib/settings/hooks/useSettingsContext";
import useUser from "lib/user/hooks/useGetUser";
import {UserContext} from "lib/user/provider/UserContext";
import React, {PropsWithChildren, useEffect} from "react";
import {useTranslation} from "react-i18next";

const getQuery = (key: string): string | null => {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
};

const switchLanguage = async (
    language: string,
    source: string
) => {
    const {i18n} = useTranslation();
    console.log(`found language in ${source}`);
    await i18n.changeLanguage(language);
    console.log(`switched language to ${language}`);
};


function UserProvider(
    props: PropsWithChildren<{}>
) {

    const {i18n} = useTranslation();
    const settingsContext = useSettingsContext();
    const {currentUser} = useUser();

    useEffect(() => {
        const setLanguage = async (userLanguage?: string, settingsLanguage?: string) => {
            const queryLanguage = getQuery("lang")
            if (queryLanguage) {
                console.log("setting language from query param");
                await i18n.changeLanguage(queryLanguage);
                return;
            }
            if (settingsLanguage) {
                console.log("setting language from settings");
                await i18n.changeLanguage(settingsLanguage);
                return;
            }
            if (userLanguage) {
                console.log("setting language from user");
                await i18n.changeLanguage(userLanguage);
                return;
            }
            console.log("setting default language");
            await i18n.changeLanguage("en");
        };
        void setLanguage(currentUser?.language, settingsContext.language);
    }, [currentUser, settingsContext.language]);

    return <UserContext.Provider value={{currentUser: currentUser}}>
        {props.children}
    </UserContext.Provider>
}

export default UserProvider;
