import {useLocalStorage} from "lib/settings/hooks/useLocalStorage";
import {SettingsState} from "lib/settings/model/SettingsState";
import {SettingsContext} from "lib/settings/provider/SettingsContext";
import ThemeMode from "lib/theme/ThemeMode";
import React, {PropsWithChildren, useMemo} from 'react';

const defaultSettings = {themeMode: ThemeMode.System, language: undefined}

function SettingsProvider(props: PropsWithChildren<{}>) {

    const values = useLocalStorage<SettingsState>("cpi_tool_settings", defaultSettings);

    const memoizedValue = useMemo(
        () => ({
            ...values.state,
            canReset: values.canReset,
            onReset: values.resetState,
            onUpdate: values.setState,
            onUpdateField: values.setField,
        }),
        [
            values.state,
            values.canReset,
            values.resetState,
            values.setState,
            values.setField,
        ]
    );

    return <SettingsContext.Provider value={memoizedValue}>
        {props.children}
    </SettingsContext.Provider>;
}

export default SettingsProvider;
