import {MontaThemeContext} from "lib/theme/provider/MontaThemeContext";
import {useContext} from 'react';

export function useMontaThemeContext() {
    const montaThemeContext = useContext(MontaThemeContext);
    if (!montaThemeContext) {
        throw new Error('useMontaThemeContext must be use inside MontaThemeContextProvider');
    }
    return montaThemeContext;
}

export default useMontaThemeContext;
