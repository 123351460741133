import MontaLayout from 'lib/layout/MontaLayout';
import React, {lazy} from 'react';
import {createBrowserRouter} from 'react-router-dom';

const ChargePage = lazy(() => import('app/pages/ChargePage.tsx'));
const ChargePointPage = lazy(() => import('app/pages/ChargePointPage.tsx'));
const ChargePointsPage = lazy(() => import('app/pages/ChargePointsPage.tsx'));
const PageNotFoundPage = lazy(() => import('app/pages/PageNotFoundPage.tsx'));

const router = createBrowserRouter([
    {
        path: '/',
        element: <MontaLayout/>,
        children: [
            {
                path: '/',
                element: <ChargePointsPage/>
            },
            {
                path: '/charge-points',
                element: <ChargePointsPage/>
            },
            {
                path: '/charge-points/:identity',
                element: <ChargePointPage/>
            },
            {
                path: '/charges/:chargeId',
                element: <ChargePage/>
            }
        ]
    },
    {
        path: '/*',
        element: <PageNotFoundPage/>
    }
]);

export default router
