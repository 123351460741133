import {Box, Container, CssBaseline} from "@mui/material";
import useIsPhone from "app/hooks/useIsPhone.ts";
import {AnalyticsUtil} from "app/util/AnalyticsUtil";
import RenderIf from "lib/components/RenderIf.tsx";
import MontaDrawer from "lib/layout/MontaDrawer";
import SettingsModal from "lib/settings/components/SettingsModal";
import React, {Suspense, useEffect} from 'react';
import {Outlet, useLocation} from "react-router-dom";

function MontaLayout() {

    const isPhone = useIsPhone();
    let location = useLocation();

    useEffect(() => {
        AnalyticsUtil.pageView(location.pathname);
    }, [location]);

    return <Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <RenderIf if={!isPhone}>
            <MontaDrawer/>
        </RenderIf>
        <Box component="main" sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        }}>
            <Container sx={{mt: 2, mb: 4}} maxWidth={false}>
                <Suspense>
                    <Outlet/>
                </Suspense>
            </Container>
        </Box>
        <SettingsModal/>
    </Box>
}

export default MontaLayout;
