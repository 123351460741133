import {PaletteMode} from "@mui/material";
import {createTheme, Theme} from '@mui/material/styles';
import * as colors from 'lib/theme/util/colors';

const baseFontStack = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
];

const PrimaryFont = ['"Instrument Sans", sans-serif'].concat(baseFontStack).join(',');

function getMontaTheme(mode: PaletteMode): Theme {
    return createTheme({
        palette: {
            mode: mode,
            primary: colors.primary,
            secondary: colors.secondary,
            text: {
                primary: mode === 'light' ? '#343457' : "#FFFFFF"
            },
        },
        typography: {
            htmlFontSize: 10,
            fontFamily: PrimaryFont,
            fontWeightBold: 700,
            fontWeightMedium: 500,
            fontWeightRegular: 400,
            allVariants: {
                fontFamily: PrimaryFont,
            },
            h1: {
                fontWeight: 700,
                fontSize: '4rem',
            },
            h2: {
                fontWeight: 700,
                fontSize: '3.5rem',
            },
            h3: {
                fontWeight: 700,
                fontSize: '3rem',
            },
            h4: {
                fontWeight: 700,
                fontSize: '2.5rem',
            },
            h5: {
                fontWeight: 700,
                fontSize: '2rem',
            },
            subtitle1: {
                fontWeight: 700,
                fontSize: '1.6rem',
            },
            subtitle2: {
                fontWeight: 500,
                fontSize: '1.5rem',
            },
            body1: {
                fontWeight: 400,
                fontSize: '1.4rem',
            },
            body2: {
                fontSize: '1.4rem',
                fontWeight: 700,
            },
            button: {
                textTransform: 'none',
                fontSize: '1.4rem',
                fontWeight: 700,
                lineHeight: '1.6rem',
            },
            caption: {
                fontWeight: 700,
                fontSize: '1.2rem',
            },
            overline: {
                fontSize: '1.2rem',
            },
        },
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: mode === 'light' ? '#F4F6F8' : 'transparent',
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    root: {
                        borderColor: mode === 'light' ? "#E2E6F0" : 'transparent',
                        boxShadow: 'none',
                        '.MuiBackdrop-root': {
                            backgroundColor: 'transparent',
                        },
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: `
                    html, body, #__next {
                      overscroll-behavior: none;
                    }
            
                    div[scale="1"] {
                      width: 100%;
                    }
            
                    html {
                      font-size: 62.5%;
                    }
            
                    body {
                      font-family: 'Instrument Sans', sans-serif;
                      font-weight: 400;
                      overflow-y: scroll;
                    }
                `,
            },
            MuiButton: {
                defaultProps: {
                    color: 'primary'
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        borderColor: mode === 'light' ? "#E2E6F0" : 'transparent',
                        borderRadius: '8px',
                        borderWidth: '1',
                        boxSizing: 'border-box',
                        position: 'relative',
                        '&:before': {
                            content: '""',
                            position: 'absolute',
                            left: '0',
                            right: '0',
                            top: '0',
                            bottom: '0',
                            zIndex: 0,
                            borderStyle: 'solid',
                            pointerEvents: 'none',
                            borderColor: mode === 'light' ? "#E2E6F0" : 'transparent',
                            borderRadius: '8px',
                            borderWidth: '1px',
                        },
                    },
                },
                defaultProps: {
                    elevation: mode === 'light' ? 0 : 1,
                },
            },
        },
    });
}

export default getMontaTheme;
