import router from "app/routes";
import SettingsProvider from "lib/settings/provider/SettingsProvider";
import MontaThemeProvider from "lib/theme/provider/MontaThemeProvider";
import UserProvider from "lib/user/provider/UserProvider";
import {SnackbarProvider} from "notistack";
import React from 'react';
import {RouterProvider} from "react-router-dom";

function App() {
    return <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <SettingsProvider>
            <MontaThemeProvider>
                <UserProvider>
                    <RouterProvider router={router}/>
                </UserProvider>
            </MontaThemeProvider>
        </SettingsProvider>
    </SnackbarProvider>
}

export default App;
