import App from "app/App";
import {AnalyticsUtil} from "app/util/AnalyticsUtil";
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'app/i18n';

AnalyticsUtil.init();

ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
).render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
