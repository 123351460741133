import {PaletteMode} from "@mui/material";
import getMontaTheme from "lib/theme/theme";
import ThemeMode from "lib/theme/ThemeMode";

export function determinePaletteMode(
    themeMode: ThemeMode,
    prefersDarkMode: boolean
): PaletteMode {
    if (themeMode === ThemeMode.System) {
        return prefersDarkMode ? 'dark' : 'light'
    } else {
        return themeMode === ThemeMode.Light ? 'light' : 'dark'
    }
}

export function determineThemeMode(
    themeMode: ThemeMode,
    prefersDarkMode: boolean
) {
    return getMontaTheme(
        determinePaletteMode(
            themeMode,
            prefersDarkMode
        )
    )
}
