import {useEffect, useState} from 'react';

const usePrefersDarkMode = () => {
    const [prefersDarkMode, setPrefersDarkMode] = useState(() => {
        // Initial state based on current system preference
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    });

    useEffect(() => {
        // Media query list for dark mode preference
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        // Callback function to handle changes
        const handleChange = (event: any) => {
            setPrefersDarkMode(event.matches);
        };

        // Add listener for changes in preference
        mediaQuery.addEventListener('change', handleChange);

        // Cleanup listener on component unmount
        return () => {
            mediaQuery.removeEventListener('change', handleChange);
        };
    }, []);

    return prefersDarkMode;
};

export default usePrefersDarkMode;
