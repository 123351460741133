import React, {PropsWithChildren} from "react";

function RenderIf(props: PropsWithChildren<{
    if: boolean;
}>): React.JSX.Element | null {
    if (!props.if) {
        return null;
    } else {
        return <>{props.children}</>;
    }
}

export default RenderIf;
