import {Avatar, IconButton, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useUserContext} from "lib/user/hooks/useUserContext";
import React from "react";

function SettingsUserAvatar(props: {
    fullMode?: boolean,
    onClick?: () => void | undefined;
}) {
    const userContext = useUserContext()
    const user = userContext.currentUser

    let userName = user?.displayName;

    if (!userName) {
        userName = user?.firstName + " " + user?.lastName
    }

    return <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center">
        <IconButton onClick={props.onClick}>
            <Avatar alt={userName} src={user?.profileImageUrl}/>
        </IconButton>
        {props.fullMode && <>
            <Box sx={{mt: 1}}/>
            <Typography textAlign="center">{userName}</Typography>
            <Typography textAlign="center">{user?.email}</Typography>
        </>}
    </Box>
}

export default SettingsUserAvatar;
