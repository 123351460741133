import {ThemeProvider} from "@mui/material";
import {AnalyticsUtil} from "app/util/AnalyticsUtil";
import {useSettingsContext} from "lib/settings/hooks/useSettingsContext";
import usePrefersDarkMode from "lib/theme/hooks/usePrefersDarkMode";
import {MontaThemeContext} from "lib/theme/provider/MontaThemeContext";
import ThemeMode from "lib/theme/ThemeMode";
import {determinePaletteMode, determineThemeMode} from "lib/theme/util/ThemeUtils";
import React, {PropsWithChildren, useEffect, useMemo, useState} from "react";

function MontaThemeProvider(
    props: PropsWithChildren<{}>
) {
    const settingsContext = useSettingsContext();
    const prefersDarkMode = usePrefersDarkMode();
    const [themeMode, setThemeMode] = useState<ThemeMode>(() => {
        return settingsContext.themeMode ?? ThemeMode.System
    });

    const theme = useMemo(
        () => determineThemeMode(themeMode, prefersDarkMode),
        [themeMode, prefersDarkMode]
    );

    const toggleTheme = () => {
        const currentMode = determinePaletteMode(themeMode, prefersDarkMode)
        if (currentMode === 'dark') {
            updateThemeMode(ThemeMode.Light)
        } else {
            updateThemeMode(ThemeMode.Dark)
        }
    };

    const updateThemeMode = (themeMode: ThemeMode) => {
        AnalyticsUtil.settingChanged("themeMode", themeMode);
        settingsContext.onUpdateField("themeMode", themeMode);
        setThemeMode(themeMode);
    };

    useEffect(() => {
        setThemeMode(settingsContext.themeMode ?? ThemeMode.System)
    }, [settingsContext.themeMode])

    return <MontaThemeContext.Provider value={{themeMode, toggleTheme, updateThemeMode}}>
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    </MontaThemeContext.Provider>
}

export default MontaThemeProvider;
