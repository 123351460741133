import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import useMontaThemeContext from "lib/theme/hooks/useMontaThemeContext";
import ThemeMode from "lib/theme/ThemeMode";
import React from "react";
import {useTranslation} from "react-i18next";

function SettingsThemeModeSelect() {

    const montaThemeContext = useMontaThemeContext();
    const {t} = useTranslation();

    const updateThemeMode = (themeMode: ThemeMode) => {
        montaThemeContext.updateThemeMode(themeMode);
    }

    return <Box sx={{padding: 2}}>
        <FormControl fullWidth>
            <InputLabel id="theme-mode-label">{t("settings.theme")}</InputLabel>
            <Select
                labelId="theme-mode-label"
                id="theme-mode"
                value={montaThemeContext.themeMode}
                label={t("settings.theme")}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                    updateThemeMode(event.target.value as ThemeMode)
                }}>
                <MenuItem value={ThemeMode.Light}>{t("theme.light")}</MenuItem>
                <MenuItem value={ThemeMode.Dark}>{t("theme.dark")}</MenuItem>
                <MenuItem value={ThemeMode.System}>{t("theme.system_setting")}</MenuItem>
            </Select>
        </FormControl>
    </Box>
}

export default SettingsThemeModeSelect;
