import {EvStation} from "@mui/icons-material";
import Box from "@mui/material/Box";
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {CSSObject, styled, Theme} from '@mui/material/styles';
import {Grid2} from "@mui/material";
import {ModalType} from "app/hooks/useModal";
import useShowModal from "app/hooks/useShowModal";
import MontaCompactLogo from "lib/layout/MontaCompactLogo.svg";
import SettingsUserAvatar from "lib/settings/components/SettingsUserAvatar";
import DarkModeSwitch from "lib/theme/components/DarkModeSwitch";
import useMontaThemeContext from "lib/theme/hooks/useMontaThemeContext";
import * as React from "react";
import {Link} from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(9)} + 1px)`
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function MontaListItem(props: {
    icon: React.JSX.Element,
    open: boolean
    route: string
}): React.JSX.Element {
    return <Link to={props.route}>
        <ListItem disablePadding sx={{display: 'block'}}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: props.open ? 'initial' : 'center',
                    px: 2.5,
                }}>
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: props.open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}>
                    {props.icon}
                </ListItemIcon>
                <ListItemText primary={"Charge Points"} sx={{opacity: props.open ? 1 : 0}}/>
            </ListItemButton>
        </ListItem>
    </Link>
}

export default function MontaDrawer() {

    const {showModal} = useShowModal();
    const montaThemeContext = useMontaThemeContext();

    return <Drawer
        variant="permanent"
        open={false}>
        <DrawerHeader sx={{justifyContent: "center"}}>
            <a href={"/"}>
                <img src={MontaCompactLogo} height={30} width={"auto"} alt={"Logo"}/>
            </a>
        </DrawerHeader>
        <List>
            <MontaListItem icon={<EvStation/>}
                           open={false}
                           route={"/charge-points"}/>
        </List>

        <List sx={{marginTop: `auto`}}>
            <Grid2 textAlign={"center"}>
                <DarkModeSwitch onClick={() => {
                    montaThemeContext.toggleTheme()
                }}/>
                <Box sx={{mt: 1}}/>
                <SettingsUserAvatar onClick={() => {
                    showModal(ModalType.Settings)
                }}/>
            </Grid2>
        </List>
    </Drawer>
}
