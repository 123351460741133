import {useEffect, useState} from "react";

function useIsPhone() {
    const [isPhone, setIsPhone] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 576px)"); // Adjust the max-width based on your XS breakpoint
        const handleResize = () => setIsPhone(mediaQuery.matches);

        // Initial check
        handleResize();

        // Attach the listener
        mediaQuery.addEventListener('change', handleResize);

        // Cleanup the listener on unmount
        return () => mediaQuery.removeEventListener('change', handleResize);
    }, []);

    return isPhone;
}

export default useIsPhone;
